import {useDispatch, useSelector} from "react-redux";
import './style.css'
import moment from "moment";
import {Avatar, Box, Button, Checkbox, Modal, Snackbar} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useEffect, useState} from "react";
import {
    deleteUniqueBagItem, getClientSecret,
    setBagInformation,
    setShowSnackbarVisibility,
    setTotalValues
} from "../../../store/Auth/AuthActions.ts";
import {checkIfWeekend} from "../../../util";
import {Alert} from "@mui/lab";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {Link, useNavigate} from "react-router-dom";
import {Switch} from '@mui/material';
import Person2Icon from '@mui/icons-material/Person2';
import UndoIcon from "@mui/icons-material/Undo";
import EmailIcon from "@mui/icons-material/Email";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 200,
    backgroundColor: '#00414A',
    boxShadow: 24,
    borderRadius: '15px',
    outline: 0,
    p: 4
};

const BagContent = ({payNow, beachSelected}) => {
    const {bagInformation, beachesInBag} = useSelector((state) => state.auth)
    const fees = beachesInBag.find((beach) => beach.id === beachSelected)?.beach_bed_fees
    const [totalBag, setTotalBag] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [guestInputs, setGuestInputs] = useState({});
    const [subTotalBag, setSubTotalBag] = useState(0);
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isSplitPayment, setIsSplitPayment] = useState()
    const [timers, setTimers] = useState([])
    const [itemCounts, setItemCounts] = useState({});
    const [checkedItems, setCheckedItems] = useState({});
    const dispatch = useDispatch();
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [email, setEmail] = useState("");
    const [isChecked, setIsChecked] = useState(false);

    const navigate = useNavigate();
    const handleCloseSnackBar = () => {
        setOpenSnackBar(false)
    }
    const onCheckboxChange = (checked, id, emailIndex) => {
        const updatedCheckedItems = {...checkedItems};
        if (!updatedCheckedItems[emailIndex]) {
            updatedCheckedItems[emailIndex] = {};
        }
        updatedCheckedItems[emailIndex][id] = checked;
        setCheckedItems(updatedCheckedItems);
    };

    useEffect(() => {
        let subTotal = 0
        let beforeTotal = 0
        bagInformation.map((bag) => {
            if (bag.reservation_type === 'default') {
                const totalSplitBed = bag.splitWith ? bag.splitWith?.reduce((accumulator, currentValue, index) => accumulator + currentValue.bedCount, 0) : 0;
                let count = bag.count - totalSplitBed
                beforeTotal = beforeTotal +
                    (bag.count * (checkIfWeekend(bag.selected_date) ?
                        Number(bag.beach_item.pivot.weekend_price) :
                        Number(bag.beach_item.pivot.weekday_price)))
                subTotal = subTotal +
                    (count * (checkIfWeekend(bag.selected_date) ?
                        Number(bag.beach_item.pivot.weekend_price) :
                        Number(bag.beach_item.pivot.weekday_price)))
            } else {
                subTotal = subTotal +
                    (bag.count * Number(bag.beach.day_pass_price))
            }
        })

        dispatch(setTotalValues(subTotal, beforeTotal))
    }, [bagInformation]);
    const calculateSubtotal = (bagInformation) => {
        let subtotal = 0;
        bagInformation.forEach(bagItem => {
            bagItem.items.forEach(item => {
                const price = checkIfWeekend(item.selected_date)
                    ? Number(item.beach_item.pivot.weekend_price)
                    : Number(item.beach_item.pivot.weekday_price);
                subtotal += item.count * price;
            });
        });
        return subtotal;
    };

    useEffect(() => {
        setSubTotalBag(calculateSubtotal(bagInformation));
    }, [bagInformation]);

    useEffect(() => {
        let newTotalAmount = 0;
        bagInformation.forEach(bag => {
            if (bag.splitWith) {
                bag.splitWith.forEach(split => {
                    const itemsForEmail = getItemsPerEmail(split, bag);
                    const splitTotal = itemsForEmail.reduce((total, item) => total + item.itemTotal, 0);
                    newTotalAmount += splitTotal;
                });
            }
        });
        setTotalAmount(newTotalAmount);
    }, [bagInformation]);

    useEffect(() => {
        const newTotalBag = subTotalBag - totalAmount;
        setTotalBag(newTotalBag);
    }, [totalAmount, subTotalBag]);

    useEffect(() => {
        const initialCounts = {};
        bagInformation.forEach((bagItem) => {
            (bagItem.itemsInDropdown || []).forEach((beachItem) => {
                initialCounts[beachItem.id] = 0;
            });
        });
        setItemCounts(initialCounts);
    }, [bagInformation]);

    const deleteBagItem = (itemId) => {
        dispatch(deleteUniqueBagItem(itemId));
    };
    const handleSplitPayment = (event, index) => {
        let data = [...bagInformation];
        let splitWith = [];
        let splitNumber = {};
        let itemsToSplit = [];

        if (event.target.checked) {
            splitNumber = data[index].items.reduce((accumulator, currentItem) => {
                return accumulator + currentItem.count;
            }, 0);
            data[index].items.forEach((item) => {
                itemsToSplit.push(item.beach_item);
            });
            for (let i = 0; i < splitNumber - 1; i++) {
                splitWith.push({
                    email: '',
                    bedCount: 0,
                    items: null,
                });
            }
        }
        data[index] = {
            ...data[index],
            splitWith: splitWith,
            splitWithFriend: event.target.checked,
            itemsInDropdown: itemsToSplit
        };
        setIsSplitPayment(data.some(item => item.splitWithFriend === true));

        data = handleOddNumber(data, index);
        dispatch(setBagInformation(data));
    };
    const handleSplitChange = (event, emailIndex, cardIndex, name) => {
        let data = JSON.parse(JSON.stringify(bagInformation));
        let total = 0
        let value = event
        if (name === 'bedCount') {
            let total = data[cardIndex].splitWith.reduce((accumulator, currentValue, index) =>
                accumulator + (index !== emailIndex ? currentValue.bedCount : 0), 0);

            let max = data[cardIndex].items.reduce((accumulator, currentItem) => {
                return currentItem.beach_item ? accumulator + currentItem.count : accumulator;
            }, 0);

            value = Math.max(0, Math.min(max - total, Number(value)));
        } else if (name === 'email') {
        } else if (name === 'items') {
            let items = data[cardIndex].splitWith[emailIndex].items || [];
            const itemExists = items.find(item => item.id === value.id);

            if (value.checked && !itemExists) {
                items.push({
                    id: value.id,
                    quantity: 1
                });
            } else if (!value.checked && itemExists) {
                items = items.filter(item => item.id !== value.id);
            }

            value = items;
        }

        data[cardIndex].splitWith[emailIndex] = {
            ...data[cardIndex].splitWith[emailIndex],
            [name]: value
        };
        data[cardIndex].splittedNumber = data[cardIndex].splitWith.reduce((accumulator, currentValue, index) => accumulator + currentValue.bedCount, 0);
        if (name === 'bedCount') {
            data[cardIndex].splittedNumber = data[cardIndex].splitWith.reduce((accumulator, currentValue) =>
                accumulator + currentValue.bedCount, 0);
        }

        dispatch(setBagInformation(data))
    }

    const handleDeleteEmail = (emailIndex, cardIndex) => {
        let data = JSON.parse(JSON.stringify(bagInformation));
        const deletedEmail = data[cardIndex].splitWith.splice(emailIndex, 1)[0];
        data[cardIndex].deletedEmails = data[cardIndex].deletedEmails || [];
        data[cardIndex].deletedEmails.push(deletedEmail);
        data = handleOddNumber(data, cardIndex);
        dispatch(setBagInformation(data));
    }
    const handleAddEmail = (cardIndex) => {
        let data = JSON.parse(JSON.stringify(bagInformation));
        if (data[cardIndex].deletedEmails && data[cardIndex].deletedEmails.length > 0) {
            const emailToAdd = data[cardIndex].deletedEmails.pop();
            data[cardIndex].splitWith.push(emailToAdd);

            data = handleOddNumber(data, cardIndex);
            dispatch(setBagInformation(data));
        } else {
        }
    }

    const handleOddNumber = (data, cardIndex) => {
        if (data[cardIndex].count % (data[cardIndex].splitWith?.length + 1) === 0) {
            data[cardIndex].assignAdditionalItem = false
        } else {
            data[cardIndex].assignAdditionalItem = true
        }
        return data
    }
    useEffect(() => {
        const initialInputs = {};
        bagInformation.forEach((bag, index) => {
            initialInputs[`bag${index}`] = bag.names.map((name) => ({...name, value: name.name}));
        });
        setGuestInputs(initialInputs);
    }, [bagInformation]);

    const handleNameChange = (bagIndex, nameIndex, event) => {
        const newGuestInputs = {...guestInputs};
        newGuestInputs[`bag${bagIndex}`][nameIndex].value = event.target.value;
        setGuestInputs(newGuestInputs);
    };
    const handlePayNow = () => {
        let data = [...bagInformation];
        let hasEmptyEmail = false;
        let invalidEmail = false;
        let dataToSend = [];
        let noItemsSelected = false;

        data.forEach((item) => {
            let transformedData = item.splitWith?.map(obj => ({
                ...obj,
                items: (obj.items ?? []).map(({id: item_id, ...rest}) => ({
                    item_id,
                    ...rest
                }))
            }));
            dataToSend.push({
                selected_date: item.selected_date,
                guests: item.names,
                splitWith: transformedData ?? null,
                splitWithFriends: item.splitWithFriend ?? false
            });
            if (item.splitWith?.length > 0) {
                hasEmptyEmail = hasEmptyEmail || item.splitWith.some(obj => !obj.email);
                invalidEmail = invalidEmail || item.splitWith.some(obj => obj.email && !validateEmail(obj.email));
                noItemsSelected = noItemsSelected || item.splitWith.some(obj => (obj.items ?? []).length === 0);
            }
        });

        if (hasEmptyEmail) {
            setErrorMessage('Please fill all the emails.');
            setOpenSnackBar(true);
            return;
        }
        if (invalidEmail) {
            setErrorMessage('Please enter a valid email.');
            setOpenSnackBar(true);
            return;
        }
        if (noItemsSelected) {
            setErrorMessage('You need to choose at least one item to split the bill.');
            setOpenSnackBar(true);
            return;
        }
        if (totalBag === 0) {
            setShowConfirmation(true);
            return;
        }
        payNow(dataToSend);
        // payNow(dataToSend).catch((error) => {
        //     if (error.response && error.response.status === 422) {
        //         setErrorMessage('An error has occured');
        //         setOpenSnackBar(true);
        //     }
        // });
    };
    const handleConfirmation = async (confirm) => {
        setShowConfirmation(false);
        if (confirm) {
            try {
                let data = [...bagInformation];
                let dataToSend = [];
                data.forEach((item) => {
                    let transformedData = item.splitWith?.map(obj => ({
                        ...obj,
                        items: (obj.items ?? []).map(({id: item_id, ...rest}) => ({
                            item_id,
                            ...rest
                        }))
                    }));
                    dataToSend.push({
                        selected_date: item.selected_date,
                        guests: item.names,
                        splitWith: transformedData ?? null,
                        splitWithFriends: item.splitWithFriend ?? false
                    });
                });

                dispatch(getClientSecret(localStorage.getItem('selected_beach'), dataToSend));
                dispatch(setShowSnackbarVisibility(true));
                navigate('/');
            } catch (error) {
                setErrorMessage('Error');
                setOpenSnackBar(true);
            }
        }
    };

    const validateEmail = (email) => {
        const validEmail = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        return validEmail.test(String(email).toLowerCase());
    }

    function calculateHoldSpot(expires_at, itemId, index, bagIndex) {
        const currentTime = moment();
        const expirationTime = moment(expires_at);
        const duration = moment.duration(expirationTime.diff(currentTime));
        if (duration.asSeconds() <= 0) {
            setTimers((prevTimers) => ({
                ...prevTimers,
                [itemId]: "0 minutes 0 seconds",
            }));
            // delete the item from the cart
            handleDeleteItem(index, bagIndex)
        } else {
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();
            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            const formattedSeconds = seconds.toString().padStart(2, '0');
            const holdSpotTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

            setTimers((prevTimers) => ({
                ...prevTimers,
                [itemId]: holdSpotTime,
            }));
            return holdSpotTime;
        }
    }

    useEffect(() => {
        const updateHoldSpot = () => {
            bagInformation?.forEach((bagItem, bagIndex) => {
                bagItem.items?.forEach((item, index) => {
                    const holdSpotTime = calculateHoldSpot(item.expires_in, item.id, index, bagIndex);
                    if (holdSpotTime === "0 minutes 0 seconds") {
                        //handle delete
                        handleDeleteItem(index, bagIndex)
                    }
                })

            });
        };
        updateHoldSpot();
        const intervalId = setInterval(updateHoldSpot, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [bagInformation]);

    function handleDeleteItem(itemIndex, bagIndex) {
        // let data = [...bagInformation];
        // data[bagIndex].items.splice(index, 1)
        //
        // dispatch(setBagInformation(data))
    }

    function canSplitTheBill(bagItem) {
        let canSplitBill = false
        const addedByAdmin = bagItem.items.some(item => item.added_by_admin === true);
        if (bagItem.items.length >= 1 && !addedByAdmin) {
            canSplitBill = true;
        }
        return canSplitBill
    }

    const getMaxItemQuantity = (id, cardIndex) => {
        return bagInformation[cardIndex].items.filter(item => item.item_id === id).reduce((total, item) => total + item.count, 0);
    }
    const getTotalItemQuantity = (id, cardIndex) => {
        const data = bagInformation[cardIndex].splitWith;
        return data.reduce((acc, split) => {
            const item = split.items && Array.isArray(split.items) ? split.items.find(item => item.id === id) : null;
            if (item) {
                acc += item.quantity;
            }
            return acc;
        }, 0);
    }
    const handleIncrement = (id, emailIndex, cardIndex) => {
        let data = JSON.parse(JSON.stringify(bagInformation));
        const maxQuantity = getMaxItemQuantity(id, cardIndex);
        const totalQuantity = getTotalItemQuantity(id, cardIndex);

        if (totalQuantity >= maxQuantity) {
            return;
        }

        let item = data[cardIndex].splitWith[emailIndex].items.find(item => item.id === id);
        if (item) {
            item.quantity += 1;
        } else {
            data[cardIndex].splitWith[emailIndex].items.push({id: id, quantity: 1});
        }

        dispatch(setBagInformation(data));
    };

    const handleDecrement = (id, emailIndex, cardIndex) => {
        let data = JSON.parse(JSON.stringify(bagInformation));

        let item = data[cardIndex].splitWith[emailIndex].items.find(item => item.id === id);
        if (item && item.quantity > 1) {
            item.quantity -= 1;
        } else if (item && item.quantity === 1) {
            data[cardIndex].splitWith[emailIndex].items = data[cardIndex].splitWith[emailIndex].items.filter(i => i.id !== id);
        }
        dispatch(setBagInformation(data));
    };

    const getItemsPerEmail = (split, bag) => {
        if (!split.items || !Array.isArray(split.items)) {
            return [];
        }
        return split.items.reduce((acc, item) => {
            if (item.quantity > 0) {
                const itemDetails = bag.itemsInDropdown.find(bagItem => bagItem.id === item.id);
                if (itemDetails) {
                    const itemPrice = checkIfWeekend(bag.selected_date) ? Number(itemDetails.pivot.weekend_price) : Number(itemDetails.pivot.weekday_price);
                    const itemTotal = item.quantity * itemPrice;
                    // const itemName = `${item.quantity} x ${itemDetails.name} (${item.quantity} * ${checkIfWeekend(bag.selected_date) ? Number(itemDetails.pivot.weekend_price) : Number(itemDetails.pivot.weekday_price)}= ${itemTotal}$) `;
                    const itemName = `${itemDetails.name}`;
                    const itemImage = itemDetails.image;
                    const itemQuantity = item.quantity
                    acc.push({itemName, itemTotal, itemImage, itemPrice, itemQuantity});
                }
            }
            return acc;
        }, []);
    };
    const joinUniqueItems = (items) => {
        const allItems = {};
        items.forEach(item => {
            if (allItems[item.id]) {
                allItems[item.id].quantity += item.quantity;
            } else {
                allItems[item.id] = {...item};
            }
        });
        return Object.values(allItems)
    };

    const includedFees = () => {
        let totalFees = 0;

        bagInformation.forEach(bagItem => {
            const itemFees = Number(fees);

            bagItem.items.forEach(item => {
                let totalQuantity = item.count;

                if (bagItem.splitWithFriend) {
                    bagItem.splitWith.forEach(split => {
                        split.items?.forEach(splitItem => {
                            if (splitItem.id === item.item.id) {
                                totalQuantity -= splitItem.quantity;
                            }
                        });
                    });
                }

                totalFees += totalQuantity * itemFees;
            });
        });

        return totalFees;
    };

    useEffect(() => {
        const totalCount = bagInformation.reduce((acc, bagItem) => acc + bagItem.items.reduce((sum, item) => sum + item.count, 0), 0);
        setTotalItemCount(totalCount);
    }, [bagInformation]);


    useEffect(() => {
        const email = localStorage.getItem('email');
        setEmail(email);
    }, []);

    return (
        <div className={"md:container md:mt-20 max-md:mt-10"}>
            <div className="md:grid grid-cols-[3fr_2fr] max-md:mx-3 gap-10">
                <div className="">

                    {bagInformation.length > 0 ?
                        bagInformation.map((bagItem, index) => (
                            <div className="row">
                                <h2 className="md:text-white md:bg-primary-green max-md:text-primary-dark-green md:py-4 max-md:text-xl font-bold rounded-lg mb-3 md:shadow-lg">
                                    Reservation Details
                                </h2>
                                <div className={"rounded-2xl md:shadow-3xl max-md:shadow-lg py-3 md:mb-5 max-md:mb-3"}>
                                    <div className="md:flex items-center justify-content-between mb-3">
                                        <img className="md:w-32 md:h-32 rounded-xl object-cover"
                                             src={bagItem.beach.thumbnail_image}/>
                                        <div className="pl-2">
                                            <h5 className={"text-primary-dark-green font-bold text-xl mt-2"}>{bagItem.beach.name}</h5>
                                            <div className="flex gap-2 items-center mt-2">
                                                <img src="/svgs/calendar-check.svg"/>
                                                <h2 className="text-primary-gray font-archivo-light text-sm">{moment(bagItem.selected_date).format("dddd MMMM, DD")}</h2>
                                            </div>
                                            <div className="flex gap-2 items-center mt-2">
                                                <img src="/svgs/map-marker.svg"/>
                                                <h2 className="text-primary-gray font-archivo-light text-sm">{bagItem.beach.location}</h2>
                                            </div>
                                        </div>
                                        <div
                                            className={"text-primary-dark-green rounded-xl p-3"}>
                                            {
                                                bagItem.items.map((item, itemIndex) => (
                                                    <>
                                                        <div
                                                            className="md:grid grid-cols-[4fr_1fr_1fr_1fr] items-center gap-3 font-archivo-light">
                                                            <div className="flex items-center gap-3">
                                                                <img
                                                                    alt="Single Bed"
                                                                    src={item.item.image}
                                                                    className="w-10 h-10"
                                                                />
                                                                <div className="py-2">
                                                                    <p className="text-sm font-archivo-light">
                                                                        {item.item.name}
                                                                    </p>
                                                                    <span
                                                                        className="text-primary-gray text-sm font-archivo-light">Remaining:</span> {timers[item.id]}
                                                                </div>
                                                            </div>
                                                            <div className="max-md:flex max-md:gap-3"><span
                                                                className="md:hidden"> Quantity :</span>
                                                                <p> x {item.count}</p>
                                                            </div>
                                                            <div className="max-md:flex max-md:gap-3">
                                                                <span className="md:hidden"> Price :</span>
                                                                <p className={"m-0"}>
                                                                    $ {item.count * (checkIfWeekend(item.selected_date) ? Number(item.beach_item.pivot.weekend_price) : Number(item.beach_item.pivot.weekday_price))}
                                                                </p>
                                                            </div>
                                                            <div onClick={() => deleteBagItem(item.id)}
                                                                 style={{cursor: 'pointer'}}
                                                                 className="col-2 mb-2 flex items-center max-md:hidden">
                                                                <Avatar className={"me-2"}
                                                                        sx={{
                                                                            bgcolor: 'rgb(249, 99, 0 , 0.3)',
                                                                            color: '#F96300',
                                                                            cursor: 'pointer',
                                                                            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                                                                            width: "30px",
                                                                            height: "30px",
                                                                        }}>
                                                                    <DeleteIcon/>
                                                                </Avatar>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    {
                                        totalItemCount > 1 && !bagItem.splitWithFriend &&
                                        <>
                                            <p className="text-primary-dark-green text-xl font-bold mt-8">All Guests</p>
                                            {bagInformation.map((bag, bagIndex) => (
                                                <div key={bagIndex} className="grid grid-cols-2 gap-x-4">
                                                    {bag.names.map((name, nameIndex) => (
                                                        <div key={nameIndex} className="my-2">
                                                            <input type="text"
                                                                   placeholder={name.name}
                                                                   value={guestInputs[`bag${bagIndex}`]?.[nameIndex]?.value || ''}
                                                                   onChange={(event) => handleNameChange(bagIndex, nameIndex, event)}
                                                                   className="rounded-md p-2 bg-primary-dark-green/5 text-primary-dark-green"/>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </>
                                    }
                                    {
                                        totalItemCount > 1 && bagItem && canSplitTheBill(bagItem) &&
                                        <div
                                            className="flex items-center text-primary-gray text-sm mt-3 cursor-pointer font-archivo-light">
                                            <Switch
                                                className="w-10"
                                                id={"toggle_switch_" + index}
                                                checked={bagItem.splitWithFriend}
                                                onChange={(e) => handleSplitPayment(e, index)}
                                                sx={{
                                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                                        color: '#F96300',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(249, 99, 0, 0.5)',
                                                        },
                                                    },
                                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                        backgroundColor: '#F96300',
                                                    },
                                                }}
                                            />
                                            <label className="cursor-pointer" htmlFor={"toggle_switch_" + index}><b>Split
                                                the bill</b></label>
                                        </div>
                                    }
                                    {
                                        bagItem.splitWithFriend &&
                                        <>
                                            <div className="row mt-3 px-3">
                                                <div className="flex items-center justify-between">
                                                    {
                                                        (bagItem.deletedEmails && bagItem.deletedEmails.length > 0) &&
                                                        <div className="my-2">
                                                            <button
                                                                className="text-primary-dark-green bg-white py-1 rounded-lg flex items-center"
                                                                onClick={() => handleAddEmail(index)}>
                                                                <Person2Icon fontSize="medium"/> Add another friend
                                                                below
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    bagItem.splitWith?.map((item, emailIndex) => (
                                                        <div key={emailIndex}>
                                                            <div
                                                                className="flex justify-between items-center bg-primary-light-green/10 py-2 px-3 rounded-lg">
                                                                <p className="text-primary-dark-green font-archivo-bold">Person {emailIndex + 1}</p>
                                                                {
                                                                    bagItem.splitWith.length - 1 > 0 &&
                                                                    <>
                                                                        <button
                                                                            onClick={() => handleDeleteEmail(emailIndex, index)}
                                                                            className="text-primary-orange underline cursor-pointer">
                                                                            remove
                                                                        </button>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div
                                                                className="mt-2 md:grid grid-cols-[2fr_3fr] justify-content-between place-content-between py-3 gap-4">
                                                                <input
                                                                    onChange={(e) => handleSplitChange(e.target.value, emailIndex, index, 'email')}
                                                                    value={item.email}
                                                                    className="form-control rounded-full"
                                                                    placeholder="Enter email"
                                                                />
                                                                {
                                                                    (bagItem.itemsInDropdown && bagItem.itemsInDropdown.length > 0) &&
                                                                    <div className="">
                                                                        {
                                                                            joinUniqueItems(bagItem.itemsInDropdown).map((beachItem, itemIndex) => {
                                                                                const itemCount = bagInformation[index]?.splitWith[emailIndex]?.items?.find(item => item.id === beachItem.id)?.quantity || 0;
                                                                                const isChecked = checkedItems[emailIndex]?.[beachItem.id] && (itemCount > 0);
                                                                                const maxQuantityReached = getTotalItemQuantity(beachItem.id, index) >= getMaxItemQuantity(beachItem.id, index);
                                                                                return (
                                                                                    <div
                                                                                        className="grid grid-cols-[2fr_1fr] items-center max-md:pt-4">
                                                                                        <div
                                                                                            className="cursor-pointer flex items-center gap-2 checkbox">
                                                                                            <input
                                                                                                onChange={(event) => {
                                                                                                    const itemData = {
                                                                                                        checked: event.target.checked,
                                                                                                        id: beachItem.id
                                                                                                    };
                                                                                                    handleSplitChange(itemData, emailIndex, index, 'items');
                                                                                                    onCheckboxChange(event.target.checked, beachItem.id, emailIndex);
                                                                                                }}
                                                                                                checked={itemCount > 0}
                                                                                                value={beachItem.id}
                                                                                                className="w-auto ms-2 cursor-pointer actual-checkbox"
                                                                                                id={`item_${itemIndex}_${emailIndex}_${index}`}
                                                                                                type="checkbox"
                                                                                                disabled={maxQuantityReached && !isChecked}
                                                                                            />
                                                                                            <label
                                                                                                className={`${maxQuantityReached && !isChecked ? 'text-gray-500 cursor-not-allowed' : 'cursor-pointer'}`}
                                                                                                htmlFor={`item_${itemIndex}_${emailIndex}_${index}`}>{beachItem.name}</label>
                                                                                        </div>
                                                                                        {isChecked && (
                                                                                            <div
                                                                                                className="flex gap-3 items-center font-archivo">
                                                                                                <div
                                                                                                    className="flex items-center gap-2">
                                                                                                    <button
                                                                                                        onClick={() => handleDecrement(beachItem.id, emailIndex, index)}
                                                                                                        disabled={itemCount <= 0}
                                                                                                        className={`bg-primary-dark-green w-6 h-6 font-bold rounded-full aspect-square cursor-pointer text-white ${itemCount <= 0 ? "cursor-not-allowed opacity-[0.5]" : ""}`}
                                                                                                    >
                                                                                                        -
                                                                                                    </button>
                                                                                                    {itemCount}
                                                                                                    <button
                                                                                                        onClick={() => handleIncrement(beachItem.id, emailIndex, index)}
                                                                                                        disabled={maxQuantityReached}
                                                                                                        className={`bg-primary-dark-green w-6 h-6 font-bold rounded-full aspect-square text-white ${maxQuantityReached ? "cursor-not-allowed opacity-[0.5]" : ""}`}
                                                                                                    >
                                                                                                        +
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        ))
                        :
                        <h2 className={"header-text max-md:pb-4"}>No items in your bag.
                        </h2>
                    }
                </div>
                <div className="">
                    <div
                        className="md:shadow-3xl max-md:shadow-lg rounded-2xl text-primary-dark-green pb-4 md:px-4 max-md:px-2 pt-2">
                        <p className="text-2xl font-bold py-3"
                           style={{borderBottom: isSplitPayment && '1px solid #F3F7F7'}}> Order Information</p>
                        <div className="mt-3">
                            {
                                isSplitPayment &&
                                <>
                                    <p className="mt-2">Payment Breakdown: </p>
                                    <div className="mt-3">
                                        {bagInformation.map((bag, index) => (
                                            bag.splitWith && bag.splitWith.map((split, sIndex) => {
                                                const itemsForEmail = getItemsPerEmail(split, bag);
                                                const totalAmount = itemsForEmail.reduce((itemQuantity, item) => itemQuantity + item.itemTotal, 0);
                                                return (
                                                    <div key={`split-${index}-${sIndex}`}
                                                         className="mt-3 text-primary-dark-green rounded-md p-3 font-archivo-light">
                                                        <div className="max-md:text-xs text-sm overflow-x-auto my-2">
                                                            <div
                                                                className="mb-4"
                                                                style={{borderBottom: isSplitPayment && '1px solid #F3F7F7'}}></div>
                                                            <div
                                                                className="overflow-x-auto flex items-center gap-1 max-md:text-lg">
                                                                <span
                                                                    className="bg-primary-orange rounded-full w-2 h-2"></span>
                                                                Paid By:
                                                                <p className="px-1 max-md:hidden">{split.email || ''}</p>
                                                            </div>
                                                            <p className="px-1 md:hidden max-md:text-[15px]">{split.email || ''}</p>
                                                            <div className="gap-4 py-4">
                                                                {itemsForEmail.map(({
                                                                                        itemName,
                                                                                        itemImage,
                                                                                        itemPrice,
                                                                                        itemQuantity,
                                                                                        itemTotal
                                                                                    }, itemIndex) => (
                                                                    <>
                                                                        <div key={itemIndex}
                                                                             className="!list-disc md:flex items-center justify-between py-2 font-archivo text-[16px]">
                                                                            <div className="flex items-center gap-1">
                                                                                <img src={itemImage} alt={itemName}
                                                                                     className="w-10 h-10"/>
                                                                                {itemName} x {itemQuantity}
                                                                            </div>
                                                                            <div className="max-md:flex max-md:pt-3">
                                                                                <p className="md:hidden">Price:</p>
                                                                                <p>
                                                                                    $ {itemTotal}
                                                                                </p>
                                                                            </div>

                                                                        </div>

                                                                    </>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="flex justify-end my-1 font-archivo text-primary-dark-green max-md:text-lg">
                                                            <p> Total: <span
                                                                className="font-archivo-bold">${totalAmount.toFixed(2)}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ))}
                                    </div>
                                </>
                            }
                            <div className="my-4" style={{borderBottom: isSplitPayment && '1px solid #F3F7F7'}}></div>
                            <div className="mt-2">
                                {isSplitPayment &&
                                    <>
                                        <div className="flex items-center justify-between font-archivo-light py-2">
                                            <b>Subtotal</b>
                                            <p>$ {subTotalBag.toFixed(2)}</p>
                                        </div>
                                        <div className="flex items-center justify-between font-archivo-light py-2">
                                            <p className="pr-2 text-lg">Your Share</p>
                                            <div className="font-archivo-bold">
                                                <p>$ {totalBag.toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            {
                                includedFees() > 0 && (
                                    <div className="flex items-center justify-between font-archivo-light py-2">
                                        <p className="pr-2 text-lg text-red-500">Included Fees*</p>
                                        <div className="font-archivo-bold">
                                            <p>${includedFees().toFixed(2)}</p>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="flex items-center mt-2">
                                <Link to="/terms-conditions"
                                      className="text-sm text-primary-dark-green m-0"
                                      target="_blank">
                                    Terms and conditions
                                </Link>
                                <Checkbox
                                    checked={isChecked}
                                    onChange={(e) => setIsChecked(e.target.checked)}
                                    sx={{color: '#B0CBCE', '&.Mui-checked': {color: '#F96300'}}}
                                />
                            </div>
                            <div className="flex justify-center">
                                <button onClick={handlePayNow}
                                        disabled={!isChecked}
                                        className={`bg-primary-orange text-white md:px-20 max-md:px-10 py-2 mt-3 rounded-full ${!isChecked ? 'cursor-not-allowed opacity-50' : ''}`}>
                                    {totalBag === 0 ? "Split All" : `Pay Now /$ ${totalBag.toFixed(2)}`}
                                    {/*Pay Now /$ {totalBag.toFixed(2)}*/}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} open={openSnackBar} autoHideDuration={6000}
                      onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="error" sx={{width: '100%'}}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Modal
                open={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                aria-labelledby="confirmation-modal-title"
                aria-describedby="confirmation-modal-description"
            >
                <Box sx={style}>
                    <p className="text-xl text-white text-center">
                        You have split all your items. <br/> Do you want to proceed?
                    </p>
                    <div className="flex items-center justify-center gap-3 mt-3">
                        <button type="button" onClick={() => handleConfirmation(true)}
                                className="bg-white text-primary-dark-green px-3 py-2 rounded-lg">Yes
                        </button>
                        <button type="button" onClick={() => handleConfirmation(false)}
                                className="bg-white text-primary-dark-green px-3 py-2 rounded-lg">No
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export default BagContent
