import {ReservationsTableData} from "../../../../constants/tableData.ts";
import Table from "../../components/Table.tsx";
import React, {useState, useCallback, useEffect} from "react";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MapIcon from '@mui/icons-material/Map';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Filters from "../../components/Filters.tsx";
import axios from "axios";
import {filtersLabel} from "../../../../constants/reservationFiltersData.ts";
import {MobileDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {Backdrop, CircularProgress, TextField} from "@mui/material";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {format} from 'date-fns';
import {createTheme, ThemeProvider, styled} from '@mui/material/styles';
import {
    changeReservationStatus
} from "../../../../store/Dashboard/Reservations/ReservationsAction.ts";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from '../../../../store';
import {setErrorMessage, setLoading} from "../../../../store/Dashboard/Reservations/ReservationsSlice.ts";
import {useNavigate} from "react-router-dom";
import {handleSelectedDateChange} from "../../../../store/BeachAdmin/BeachAdminActions.js";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Snackbar} from '@mui/material';
import {Alert} from "@mui/lab";
import ClearIcon from '@mui/icons-material/Clear';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {enUS} from 'date-fns/locale';

const theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
                        display: 'none',
                    },
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#00414A',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorPrimary.css-1e6y48t-MuiButtonBase-root-MuiButton-root': {
                        color: 'red',
                    },
                },
            },
        },
    },
});
const Reservations: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isLoading = useSelector((state: RootState) => state.reservations.isLoading);
    const [rows, setRows] = useState(ReservationsTableData.rows);
    const [search, setSearch] = useState(localStorage.getItem('searchInput') || '');
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [reservationsFilterData, setReservationsFilterData] = useState({
        filters: []
    });
    const [selectedFilters, setSelectedFilters] = useState({});
    const errorMessage = useSelector((state: RootState) => state.reservations.errorMessage);

    const [tags, setTags] = useState()
    const beachId = sessionStorage.getItem('beach_id');
    const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
    const [bothDatesSelected, setBothDatesSelected] = useState(false);

    const [dateRange, setDateRange] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection',
        }
    ]);

    const handleDateRangePickerOpen = () => {
        setIsDateRangePickerOpen(true);
    };

    const handleDateRangePickerClose = () => {
        if (bothDatesSelected) {
            setIsDateRangePickerOpen(false);
        }
    };

    const handleSelect = (ranges) => {
        const {startDate, endDate} = ranges.selection;
        setDateRange([{
            ...ranges.selection,
            startDate: startDate ? new Date(startDate) : null,
            endDate: endDate ? new Date(endDate) : null,
        }]);
        setBothDatesSelected(startDate && endDate);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearch(value);
        localStorage.setItem('searchInput', value);
    };
    const handleClearSearch = () => {
        setSearch('');
        localStorage.removeItem('searchInput');
    };

    const reservations = rows.filter(row => {
        return Object.values(row).some(value =>
            String(value).toLowerCase().includes(search.toLowerCase())
        );
    });
    const applyFilters = async (filters) => {
        setSelectedFilters(filters);
        await getReservations(selectedDate ? format(selectedDate, 'yyyy-MM-dd') : '', filters);
    };
    const resetFilters = () => {
        console.log('reset');
    };
    const openFilter = () => {
        setIsFilterOpen(!isFilterOpen);
    };
    const toggleFilterSideBar = () => {
        setIsFilterOpen(!isFilterOpen);
    };
    const handleDatePickerOpen = () => {
        setIsDatePickerOpen(true);
    };
    const handleOkButtonClick = async (date) => {
        setIsDatePickerOpen(false);
        setSelectedDate(date);
        if (date) {
            const formattedDate = format(date, 'yyyy-MM-dd');
            await getReservations(formattedDate);
            dispatch(handleSelectedDateChange(formattedDate));

        }
    };
    const handleCloseCalendar = async () => {
        setIsDatePickerOpen(false);

    };
    const getReservations = async (date = '', filters = {}, startDate = '', endDate = '') => {
        dispatch(setLoading(true));
        try {
            let params = {
                date: date || localStorage.getItem('selectedDate'),
                start_date: startDate,
                end_date: endDate,
            };
            if (filters.reservation_status) {
                params.reservation_status = JSON.stringify(filters.reservation_status);
            }
            if (filters.reservation_types) {
                params.type = filters.reservation_types;
            }
            const response = await axios.get(`/beach/${beachId}/get-reservations`, {params});

            const reservationData = response.data.data.reservations.map(reservation => ({
                id: reservation.id,
                guestName: reservation.guest_name,
                phoneNumber: reservation.phone_number,
                reservationType: reservation.reservation_type,
                date: format(new Date(reservation.date), 'dd/MM/yyyy'),
                reservedItem: reservation.reserved_item,
                note: reservation.note,
                amount: reservation.amount != null ? reservation.amount : 0,
                status: reservation.status.charAt(0).toUpperCase() + reservation.status.slice(1),
                userId: reservation.user?.id,
                reservationTags: reservation.tags.join(', '),
            }));

            setRows(reservationData);

            let types = response.data.data.reservation_types;
            types = types.filter(item => item !== 'day_pass');
            types = types.map((item) => ({
                label: filtersLabel.reservation_types[item],
                value: item,
            }));

            setReservationsFilterData({
                filters: [
                    {
                        category: 'reservation_types',
                        type: 'radio',
                        options: types,
                    },
                    {
                        category: 'reservation_status',
                        type: 'checkbox',
                        options: response.data.data.reservation_status.map((item) => ({
                            label: filtersLabel.reservation_status[item],
                            value: item,
                        })),
                    },
                ],
            });
            const tags = response.data.data.tags.map(tag => ({label: tag.label, value: tag.id}));
            setTags(tags);
        } catch (error) {
            console.error("Error", error);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleEditRow = useCallback(
        async ({id, field, value}) => {
            if (field === 'status') {
                try {
                    await dispatch(changeReservationStatus(id, value));
                    setRows((prevRows) =>
                        prevRows.map((row) => (row.id === id ? {...row, [field]: value} : row))
                    );
                } catch (error) {
                    console.error("Error updating status:", error);
                }
            } else {
                setRows((prevRows) =>
                    prevRows.map((row) => (row.id === id ? {...row, [field]: value} : row))
                );
            }
        },
        [dispatch]
    );

    const mapReservations = () => {
        navigate('/reservations/map?mode=reservationDetails')
    }
    const handleAddNewReservation = () => {
        navigate('/reservations/map?mode=addNew');
    }
    // const handleExportExcel = () => {
    //     dispatch(exportExcelReservations(
    //         selectedDate ? format(selectedDate, 'yyyy-MM-dd') : '',
    //         selectedFilters,
    //         search
    //     ));
    // };
    const handleCloseSnackbar = () => {
        dispatch(setErrorMessage(null));
    };
    const handleSubmitDateRange = async () => {
        const startDate = dateRange[0].startDate ? format(dateRange[0].startDate, 'yyyy-MM-dd') : '';
        const endDate = dateRange[0].endDate ? format(dateRange[0].endDate, 'yyyy-MM-dd') : '';

        await getReservations('', {}, startDate, endDate);
        setIsDateRangePickerOpen(false);
    };
    const handleClearDateRange = () => {
        setDateRange([{
            startDate: null,
            endDate: null,
            key: 'selection',
        }]);
    };
    useEffect(() => {
        getReservations();
    }, []);

    useEffect(() => {
        const date = localStorage.getItem('selectedDate');
        if (date) {
            setSelectedDate(new Date(date));
            getReservations(date);
        }
    }, []);

    useEffect(() => {
        if (selectedDate) {
            localStorage.setItem('selectedDate', format(selectedDate, 'yyyy-MM-dd'));
        }
    }, [selectedDate]);

    return (
        <>
            {isLoading && (
                <Backdrop
                    open={true}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            )}
            <div className="container py-10">
                <p className="text-primary-dark-green font-archivo-bold text-xl pb-4">Reservations</p>
                <div className="md:flex justify-content-between items-center">
                    <div className="relative my-4">
                        <SearchIcon
                            style={{color: "#F96300"}}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2"
                        />
                        <input
                            type="text"
                            value={search}
                            onChange={handleSearchChange}
                            className="pl-10 pr-10 py-2 border border-gray-300 rounded w-full"
                            placeholder="Search..."
                        />
                        {search && (
                            <button
                                onClick={handleClearSearch}
                                className="absolute right-3 top-3 transform -translate-y-1/2"
                            >
                                <ClearIcon/>
                            </button>
                        )}
                    </div>

                    <div className="flex gap-3 items-center">
                        <div className="bg-[#F3F7F7] rounded-lg p-2">
                            <MapIcon className="cursor-pointer text-[#00414A]" onClick={mapReservations}/>
                        </div>
                        <div className="bg-[#F3F7F7] rounded-lg p-2" onClick={openFilter}>
                            <FilterAltIcon className="cursor-pointer text-[#00414A]"/>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="bg-[#F3F7F7] rounded-lg p-2" onClick={handleDatePickerOpen}>
                                <CalendarMonthIcon className="cursor-pointer text-[#00414A]"/>
                            </div>
                            {selectedDate && (
                                <span>{format(selectedDate, 'MM/dd/yyyy')}</span>
                            )}
                            <ThemeProvider theme={theme}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDatePicker
                                        open={isDatePickerOpen}
                                        onAccept={handleOkButtonClick}
                                        onClose={handleCloseCalendar}
                                        value={selectedDate}
                                        // onChange={handleDateChange}
                                        renderInput={(params) => <TextField {...params} sx={{display: 'none'}}/>}
                                    />
                                </LocalizationProvider>
                            </ThemeProvider>
                        </div>
                        <button type="button" className="text-white rounded-md p-2 bg-primary-dark-green text-sm"
                                onClick={handleAddNewReservation}>Add
                            New
                        </button>
                        {/*<button type="button" className="underline text-sm text-primary-orange"*/}
                        {/*        onClick={handleExportExcel}>*/}
                        {/*    Export Excel*/}
                        {/*</button>*/}

                        {isDateRangePickerOpen && (
                            <div
                                className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                                <div className="relative bg-white p-[20px] rounded-md">
                                    <button
                                        onClick={() => setIsDateRangePickerOpen(false)}
                                        className="text-primary-dark-green absolute top-0 right-[10px] bg-none border-0 text-lg cursor-pointer z-10 font-bold"
                                    >
                                        X
                                    </button>
                                    <div className="mt-3">
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={handleSelect}
                                            moveRangeOnFirstSelection={false}
                                            ranges={dateRange[0].startDate && dateRange[0].endDate ? dateRange : [{
                                                startDate: new Date(),
                                                endDate: new Date(),
                                                key: 'selection'
                                            }]}
                                            locale={enUS}
                                            rangeColors={['#00414A']}
                                            color={'#00414A'}
                                        />
                                        <button
                                            type="button"
                                            className="text-white rounded-md p-2 bg-primary-dark-green text-sm mx-4 flex flex-col justify-end items-end"
                                            onClick={handleSubmitDateRange}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Snackbar
                            open={!!errorMessage}
                            autoHideDuration={6000}
                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        >
                            <Alert onClose={handleCloseSnackbar} severity="error">
                                {errorMessage}
                            </Alert>
                        </Snackbar>
                    </div>
                </div>
                {/*<div className="flex items-center gap-2 py-3">*/}
                {/*    <div className="bg-[#F96300]/10 rounded-lg p-2" onClick={handleDateRangePickerOpen}>*/}
                {/*        <CalendarMonthIcon className="cursor-pointer text-[#F96300]"/>*/}
                {/*    </div>*/}
                {/*    {dateRange[0].startDate && dateRange[0].endDate ? (*/}
                {/*        <span>{`${format(dateRange[0].startDate, 'MM/dd/yyyy')} - ${format(dateRange[0].endDate, 'MM/dd/yyyy')}`}</span>*/}
                {/*    ) : (*/}
                {/*        <span>Start-Date/End-Date</span>*/}
                {/*    )}*/}
                {/*</div>*/}
                <div className="flex items-center gap-2 py-3">
                    <div className="bg-[#F96300]/10 rounded-lg p-2" onClick={handleDateRangePickerOpen}>
                        <CalendarMonthIcon className="cursor-pointer text-[#F96300]"/>
                    </div>
                    {dateRange[0].startDate && dateRange[0].endDate ? (
                        <span>{`${format(dateRange[0].startDate, 'MM/dd/yyyy')} - ${format(dateRange[0].endDate, 'MM/dd/yyyy')}`}</span>
                    ) : (
                        <span>Start-Date/End-Date</span>
                    )}
                    <button
                        type="button"
                        className="text-primary-orange rounded-md px-2 underline text-sm"
                        onClick={handleClearDateRange}
                    >
                        Clear
                    </button>
                </div>

                <Table
                    columns={ReservationsTableData.columns}
                    rows={reservations}
                    setRows={setRows}
                    onEditCellChangeCommitted={handleEditRow}
                    pageType="reservations"
                    tags={tags}
                />
                <Filters filterData={{...reservationsFilterData, open: isFilterOpen, toggleDrawer: toggleFilterSideBar}}
                         onApply={applyFilters} onReset={resetFilters} filtersLabel={filtersLabel}/>
            </div>
        </>
    )
}
export default Reservations;
