import axios from "axios";
import {homeSlice} from "./HomeSlice";
import {mapSlice} from "../Map/MapSlice";
import {setAllBeaches, setSearchedBeaches,setEmailError} from "../HomePage/HomePageSlice";
import {setShowSnackbar} from "../Auth/AuthSlice.ts";

export const getAllBeaches = () => {
    return async (dispatch) => {
        let countryId = localStorage.getItem('country_id')
        dispatch(homeSlice.actions.setIsLoading(true))
        const response = await axios.get('/country/' + countryId + '/beaches')
        let allBeaches = response.data.data.beaches.map((beach)=>(
            {
                ...beach,
                position:{
                    lat: Number(beach.latitude),
                    lng: Number(beach.longitude)
                }
            }
        ))
        dispatch(homeSlice.actions.setAllBeaches(allBeaches))
        dispatch(homeSlice.actions.setAllBeachesMap(allBeaches))
        dispatch(homeSlice.actions.setIsLoading(false))
    }
}


export const filterBeach = (params) => {
    return async (dispatch) => {
        let countryId = localStorage.getItem('country_id')
        dispatch(homeSlice.actions.setIsLoadingFilter(true))
        const response = await axios.get('/country/' + countryId + '/beaches' , {params:params})
        let allBeaches = response.data.data.beaches
        dispatch(setAllBeaches(allBeaches))
    }
}


export const getHomePageInformation = () => {
    return async (dispatch) => {
        let countryId = localStorage.getItem('country_id')
        dispatch(homeSlice.actions.setIsLoading(true))
        dispatch(homeSlice.actions.setIsLoadingPopular(true))
        await axios.get('/country/' + countryId + '/home-beaches').then((response) => {
            dispatch(homeSlice.actions.setBackgroundSlider(response.data.data.background_slider))
            let popularThisSeason = response.data.data.popular_beaches.map((item, index) => ({
                ...item,
                index: index
            }))
            dispatch(homeSlice.actions.setPopularThisSeason(popularThisSeason))
            dispatch(homeSlice.actions.setMinPrice(response.data.data.min_price))
            dispatch(homeSlice.actions.setMaxPrice(response.data.data.max_price))

            dispatch(homeSlice.actions.setIsLoading(false))
            dispatch(homeSlice.actions.setIsLoadingPopular(false))

        })

    }
}

export const getFilters = () => {
    return async (dispatch) => {
        let countryId = localStorage.getItem('country_id')

        dispatch(homeSlice.actions.setIsLoading(true))
        await axios.get('/country/' + countryId + '/locations').then((response) => {
            dispatch(homeSlice.actions.setLocationFilters(response.data.data))
        })
        dispatch(homeSlice.actions.setIsLoading(false))
    }
}

export const searchBeaches = (search) => {
    return async (dispatch) => {
        let countryId = localStorage.getItem('country_id')
        dispatch(homeSlice.actions.setIsLoading(true))
        await axios.get('/country/' + countryId + '/search-beaches',
            {
                params:search
            }
        ).then((response) => {
            dispatch(setAllBeaches(response.data.data))
        })
            .catch((error)=>{
                console.log(error)
            })
        dispatch(homeSlice.actions.setIsLoading(false))
    }
}

export const globalSearchBeaches = (search) => {
    return async (dispatch) => {
        let countryId = localStorage.getItem('country_id')
        dispatch(homeSlice.actions.setIsLoading(true))
        await axios.get('/country/' + countryId + '/search-beaches',
            {
                params: {search:search}
            }
        ).then((response) => {
            dispatch(setSearchedBeaches(response.data.data))
        })
            .catch((error)=>{
                console.log(error)
            })
        dispatch(homeSlice.actions.setIsLoading(false))
    }
}



export const setFiltersValue = (data) => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setLocationFilters(data))
    }
}

export const handleSelectedItems = (data, totalItems, totalAmount) => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setSelectedItems(data))
        dispatch(homeSlice.actions.setTotalItems(totalItems))
        dispatch(homeSlice.actions.setTotalAmount(totalAmount))
    }
}

export const setSelectedCountry = (data) => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setSelectedCountry(data))
        localStorage.setItem('country_id', data)
    }
}


export const getCountries = () => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setIsLoading(true))
        await axios.get('/countries').then((response) => {
            dispatch(homeSlice.actions.setCountries(response.data.data))
        })
        dispatch(homeSlice.actions.setIsLoading(false))
    }
}

export const getBeachIdByKey = (key) => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setIsLoading(true))
        await axios.get('/beach-by-key?key=' + key )
            .then((response) => {
                sessionStorage.setItem('beach_url_key' , key)
                localStorage.setItem('selected_beach' , response.data.data)
            })
        dispatch(homeSlice.actions.setIsLoading(false))
    }
}

export const getBeachInfoById = () => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setIsLoading(true))
        await axios.get('/beach/' + localStorage.getItem('selected_beach'))
            .then((response) => {
                localStorage.setItem('country_id',response.data.data.country_id)
                localStorage.setItem('bag_beach_id' , response.data.data.id)

                dispatch(homeSlice.actions.setSelectedBeach(response.data.data))
            })
        dispatch(homeSlice.actions.setIsLoading(false))
    }
}

export const getMapByBeachId = (date) => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setIsLoading(true))
        const response = await axios.get('beach/' + localStorage.getItem('selected_beach') + '/map?date=' + date)
        let map = response.data.data.map
        let numberOfSeats = 0
        let numberOfSeatsTaken = 0
        dispatch(homeSlice.actions.setIsFull(false))

        map.map((item) => {
            if (item.type === "ITEM" && item.can_be_reserved) {
                numberOfSeats = numberOfSeats + 1
            }
            if (item.is_reserved) {
                numberOfSeatsTaken = numberOfSeatsTaken + 1
            }
        })
        if (numberOfSeats === numberOfSeatsTaken) {
            dispatch(homeSlice.actions.setIsFull(true))
        }
        dispatch(homeSlice.actions.setBeachMap(response.data.data.map))
        dispatch(homeSlice.actions.setInitialBeachMap(response.data.data.map))
        dispatch(homeSlice.actions.setDayPassPrice(response.data.data.day_pass_price))
        dispatch(homeSlice.actions.setMapScreenHeight(response.data.data.map_initial_height))
        dispatch(homeSlice.actions.setMapScreenWidth(response.data.data.map_initial_width))
        dispatch(homeSlice.actions.setIsLoading(false))
    }
}

export const modifytInitialMap = (map) => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setInitialBeachMap(map))
    }
}

export const setMap = (map) => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setBeachMap(map))
    }
}
export const getBuilderInfo = () => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setIsLoading(true))
        const response = await axios.get('beach/' + localStorage.getItem('selected_beach') + '/section-builder')
        dispatch(homeSlice.actions.setItemBackground(response.data.data))
        dispatch(homeSlice.actions.setIsLoading(false))
    }
}

export const addToBag = (dataUnique, selectedItems) => {
    return async (dispatch) => {
      await localStorage.setItem('card', JSON.stringify(dataUnique))
      await localStorage.setItem('selectedItems', JSON.stringify(selectedItems))
      dispatch(homeSlice.actions.setShowSideBar(true))
    }
}
export const toggleBagSideBar = (data) => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setShowSideBar(data))
    }
}

export const addToCartAction = (data , card) => {
    return async (dispatch) => {
        try {
            const response = await axios.post('/beach/' + localStorage.getItem('selected_beach') + '/add-to-bag', {data: data , unique:card})
        } catch (e) {
            console.log(e)
        }
    }
}
export const handleSearchChange = (data) => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setSearch(data))
    }
}

export const getGoogleMapsInfo = () => {
    return async (dispatch) => {
        let countryId = localStorage.getItem('country_id')
        const response = await axios.get('/country/' + countryId + '/beaches')

    }
}
export const resetMapInfo = () => {
    return async (dispatch) => {
        dispatch(homeSlice.actions.setBeachMap([]))
        dispatch(homeSlice.actions.setInitialBeachMap([]))
        dispatch(homeSlice.actions.setDayPassPrice(''))
        dispatch(homeSlice.actions.setMapScreenHeight(995))
        dispatch(homeSlice.actions.setMapScreenWidth(1920))

    }
}
export const updateEmail = (email) => {
    return async (dispatch) => {
        try {
            await axios.post('/update-email', { email });
            localStorage.setItem('email', email);
            dispatch(setShowSnackbar(true));
            dispatch(setEmailError(null));
        } catch (error) {
            dispatch(setEmailError("Failed to update email"));
        }
    };
};
