import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

const TagsCheckboxes = ({ selectedTags = [], allTags = [], onChange }) => {
    return (
        <FormGroup>
            {allTags.map(tag => (
                <div className="grid grid-cols-2" key={tag.value}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedTags.includes(tag.value)}
                                onChange={() => onChange(tag.value)}
                                name={tag.label}
                            />
                        }
                        label={tag.label}
                    />
                </div>
            ))}
        </FormGroup>
    );
};
export default TagsCheckboxes;
