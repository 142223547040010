import {beachInfoData} from "../../../constants/beachInfoData";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export const AboutBeach = () => {
    const navigate = useNavigate();
    const [isServicesVisible, setIsServicesVisible] = useState(false);
    const [isMobileScreen, setIsMobileScreen] = useState(false)
    const [isPricingVisible, setIsPricingVisible] = useState(false);
    const redirectToGoogleMaps = () => {
        navigate('/google-maps')
    }
    const redirectToMap = () => {
        navigate('/select-seats')
    }

    const openServices = () => {
        setIsServicesVisible(!isServicesVisible);
    };
    const openPricing = () => {
        setIsPricingVisible(!isPricingVisible);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const {selectedBeach} = useSelector((state) => state.home)
    return (
        <div>
            <div className="md:flex justify-between ittems-center">
                <h2 className="text-2xl text-primary-dark-green uppercase font-bold">About</h2>
                <div className="flex gap-2 max-md:mt-5">
                    {
                        selectedBeach?.tags?.map((tag) => (
                            <p className="text-primary-orange bg-primary-orange/30 rounded-md px-4 py-1 max-md:text-sm">
                                {tag.value}
                            </p>
                        ))
                    }
                </div>
            </div>
            <div className="text-md text-primary-gray">
                <p className="pt-10">{selectedBeach.about}</p>
            </div>

            {
                !isMobileScreen &&
                <div className="md:flex items-center gap-2 mt-5 mb-4">
                    {selectedBeach.services && selectedBeach.services.map((service) => (
                        <div
                            className="flex items-center max-md:my-3 max-md:w-1/2 gap-2 px-3 py-2 rounded-md bg-primary-dark-green/10 text-primary-dark-green">
                            <img className={"h-6 w-6"} src={service.icon} alt={service.title}/>
                            {service.title}
                        </div>
                    ))}
                </div>
            }

            <div className="flex items-center gap-2">
                <button onClick={redirectToMap} type="button"
                        className="bg-primary-dark-green rounded-full text-white px-4 py-2">Book Now
                </button>
                <button onClick={redirectToGoogleMaps}
                        className="cursor-pointer border !border-primary-dark-green rounded-full p-2">
                    <img src="/svgs/map-green.svg" className="w-full h-full"/>
                </button>
            </div>
            <div className="flex gap-2 text-primary-gray pt-2">
                <img src="/svgs/info.svg"/>
                {selectedBeach.note}
            </div>

            {isMobileScreen ? (
                <div className="py-4">
                    <div
                        className="border border-primary-gray rounded-lg text-primary-gray px-3 py-2 cursor-pointer">
                        <div className="flex items-center justify-between" onClick={openServices}>
                            <p style={{color: isServicesVisible ? '#00414A' : '#A1A1A1'}}>Services </p>
                            <img
                                src="/svgs/down-arrow.svg"
                                style={{
                                    transform: isServicesVisible ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease'
                                }}
                                alt="Toggle Visibility"
                            />
                        </div>
                        {isServicesVisible && (
                            <>
                                <p className="border-[0.6px] border-primary-gray/30 my-3"></p>
                                <div
                                    className="grid grid-cols-2 place-content-center place-items-center gap-2 mb-4">
                                    {selectedBeach.services && selectedBeach.services.map((service) => (
                                        <div
                                            className="gap-2 px-3 py-2 w-full text-sm flex flex-col items-center rounded-md bg-primary-dark-green/10 text-primary-dark-green">
                                            <img className={"h-6 w-6 my-2"} src={service.icon}
                                                 alt={service.title}/>
                                            {service.title}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                    <div
                        className="border border-primary-gray rounded-lg text-primary-gray px-3 py-2 cursor-pointer mt-4">
                        <div className="flex items-center justify-between" onClick={openPricing}>
                            <p style={{color: isPricingVisible ? '#00414A' : '#A1A1A1'}}>Our Pricing</p>
                            <img
                                src="/svgs/down-arrow.svg"
                                style={{
                                    transform: isPricingVisible ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease'
                                }}
                                alt="Toggle Pricing Visibility"
                            />
                        </div>
                        {isPricingVisible && (
                            <>
                                <p className="border-[0.6px] border-primary-gray/30 my-3"></p>
                                <div className="md:grid grid-cols-3 gap-10 mt-4">
                                    {selectedBeach.items && selectedBeach.items.map((item, index) => (
                                        item.is_a_seat === 1 && (
                                            <div className="pl-4 max-md:my-5" style={{
                                                borderRight: index < selectedBeach.items.length - 1 ? '1px solid #cccccc66' : 'none',
                                                paddingRight: '40px'
                                            }}>
                                                <div
                                                    className="text-primary-dark-green flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <p className="md:text-lg max-md:text-sm font-bold">{item.name}</p>
                                                        {item.pivot.number_of_persons &&
                                                            <p className={"ms-1 text-sm"}> /{' ' + item.pivot.number_of_persons} {item.pivot.number_of_persons > 1 ? ' Pers' : 'Pers'}</p>
                                                        }
                                                    </div>
                                                    <img className={"w-8 h-8"} src={item.image}
                                                         alt={`Image for ${item.name}`}/>
                                                </div>
                                                <div className="pt-2 text-primary-gray">
                                                    <div className="flex items-center gap-2">
                                                        <p><span
                                                            className="text-primary-orange">$ </span>{item.pivot.weekday_price}
                                                        </p>
                                                        <p>Weekday Price</p>
                                                    </div>
                                                    <div className="flex items-center gap-2">
                                                        <p><span
                                                            className="text-primary-orange">$ </span>{item.pivot.weekend_price}
                                                        </p>
                                                        <p>Weekend Price</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="md:grid grid-cols-3 gap-10 pt-4">
                    {selectedBeach.items &&
                        selectedBeach?.items.map((item, index) => (
                            item.is_a_seat === 1 &&
                            (
                                <div className="pl-4 max-md:my-5" style={{
                                    borderRight: index < selectedBeach.items.length - 1 ? '1px solid #cccccc66' : 'none',
                                    paddingRight: '40px'
                                }}>
                                    <div className="text-primary-dark-green flex items-center justify-between">
                                        <div className="flex items-center">
                                            <p className="md:text-lg max-md:text-sm font-bold">{item.name}</p>
                                            {
                                                item.pivot.number_of_persons &&
                                                <p className={"ms-1 text-sm"}> /{' ' + item.pivot.number_of_persons} {item.pivot.number_of_persons > 1 ? ' Pers' : 'Pers'}</p>
                                            }
                                        </div>
                                        <img className={"w-8 h-8"} src={item.image} alt={`Image for ${item.name}`}/>
                                    </div>
                                    <div className="pt-2 text-primary-gray">
                                        <div className="flex items-center gap-2">
                                            <p><span className="text-primary-orange">$ </span>{item.pivot.weekday_price}
                                            </p>
                                            <p>Weekday Price</p>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <p><span className="text-primary-orange">$ </span>{item.pivot.weekend_price}
                                            </p>
                                            <p>Weekend Price</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}
                </div>
            )}

        </div>
    )
}
export default AboutBeach;
