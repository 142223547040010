import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getBeachDashboardInformation,
    getBuilderInfo, modifyMap, setLoading, setMap, handleSelectedDateChange
} from "../../../../store/BeachAdmin/BeachAdminActions";
import DisabledAdminItem from '../../../BeachDashboard/BeachMapView/DisabledAdminItem';
import DisabledAdminSection from "../../../BeachDashboard/BeachMapView/DisabledAdminSection";
import ReservationsDetails from "./components/ReservationsDetails.tsx";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import {Space} from "react-zoomable-ui";
import AddNewReservation from "./components/AddNewResrvation.tsx";
import {useLocation, useNavigate} from 'react-router-dom';
import {checkIfWeekend} from "../../../../util";
import KingBedIcon from '@mui/icons-material/KingBed';
import LoopIcon from '@mui/icons-material/Loop';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const ReservationsMap = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [reservationDate, setReservationDate] = useState(null);

    const {
        beach,
        reservations,
        isLoading,
        stats,
        isSwapping,
        mapScreenWidth,
        mapScreenHeight,
        openMapModal,
        selectedDate,
        map,
        initialMap,
        showItemModal,
        addNewReservationModal,
        openSettingsModal,
        openAddSettingsModal,
        addNewCustomReservation,
        changeDate
    } = useSelector((state) => state.beachAdmin);
    const [loaded , setLoaded] = useState(false)
    const [selectedReservation, setSelectedReservation] = useState(true);
    const [viewMode, setViewMode] = useState('reservationDetails');
    const [selectedBeds, setSelectedBeds] = useState([]);
    const [formData, setFormData] = useState({
        email: '',
        numberOfPeople: '',
        selectUser: null
    });

    const [clickedItemIndex, setClickedItemIndex] = useState(null);

    useEffect(() => {
        currentMap.current = map;
    }, [map]);

    const [search, setSearch] = useState(localStorage.getItem('searchInput') || '');
    const currentMap = useRef(map)
    const navigate = useNavigate();
    const handleResize = () => {
        if (openMapModal) {
            return;
        }
        dispatch(setLoading(true));
        let initialSize = mapScreenWidth;

        let currentMapSize = window.innerWidth;
        let currentSize = currentMapSize;
        let ratio = 1;
        let currentMapSizeHeight = window.innerHeight;
        let heightRatio = 1;
        console.log(heightRatio)
        let data = initialMap?.map((section , index) => ({
            ...section,
            startX: section.startX / ratio,
            endX: section.endX / ratio,
            startY: section.startY / heightRatio,
            endY: section.endY / heightRatio,
            height: (section.special_height ?? 50) / heightRatio + 'px',
            width: (section.special_width ?? 50) / heightRatio + 'px',
            is_selected_to_add : currentMap.current[index].is_selected_to_add ?? false
        }));
        dispatch(setMap(data));
        dispatch(setLoading(false));
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [initialMap]);

    useEffect(() => {
        handleResize();
    }, [initialMap]);

    useEffect(() => {
        handleResize();
    }, [openMapModal]);

    useEffect(() => {
        dispatch(getBuilderInfo());
    }, [dispatch]);

    useEffect(() => {
        const date = selectedDate ?? localStorage.getItem('selectedDate');
        if (date) {
            dispatch(handleSelectedDateChange(date));
            dispatch(getBeachDashboardInformation(selectedDate)).then(()=>{
                setLoaded(true)
            })

        }
    }, []);


    const spaceRef = useRef(null);

    const initViewPort = async (vp) => {
        vp.setBounds({x: [-1500, 3000], y: [-1500, 3000]});
        vp.camera.centerFitAreaIntoView({
            left: 0,
            top: 0,
            width: window.innerWidth,
            height: mapScreenHeight
        });
    };

    const handleToggleClick = () => {
        setSelectedReservation(!selectedReservation);
    };
    const saveFormData = () => {
        localStorage.setItem('reservationFormData', JSON.stringify(formData));
    };
    const handleAddNewReservationClick = async () => {
        saveFormData();
        setViewMode('addNew');
        setSelectedReservation(true);
        setSelectedReservation(true);
        dispatch(getBeachDashboardInformation(selectedDate));
    };
    const handleFinishAdding = () => {
        const updatedInitialMap = map.map((item) => ({
            ...item,
            is_selected_to_add: false,
        }));
        dispatch(modifyMap(updatedInitialMap));
        setViewMode('reservationDetails');
    };
    const renderView = () => {
        // const filteredReservations = reservations.filter(reservation =>
        //     (reservation.user?.first_name?.toLowerCase().includes(search.toLowerCase()) ||
        //         reservation.user?.last_name?.toLowerCase().includes(search.toLowerCase()) ||
        //         reservation.guest_name?.toLowerCase().includes(search.toLowerCase()))
        // );

        switch (viewMode) {
            case 'reservationDetails':
                return loaded && <ReservationsDetails stats={stats} reservations={reservations} search={search}
                                                       setSearch={setSearch}/>
            case 'addNew':
                return <>
                    {
                        new Date(selectedDate) < new Date().setHours(0, 0, 0, 0) &&
                        <p className={"rounded-sm m-2 text-primary-orange bg-primary-orange/20 p-2"}>You have selected
                            an old date</p>
                    }
                    <AddNewReservation formData={formData} setFormData={setFormData}
                                       finishAddingReservation={handleFinishAdding}/>
                </>
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const mode = searchParams.get('mode');
        if (mode) {
            setViewMode(mode);
        }
    }, [location]);

    useEffect(() => {
        let items = map?.filter((item) => item.is_selected_to_add === true)
        setSelectedBeds(groupItems(items))
    }, [map]);
    const groupItems = (items) => {
        const countMap = {};
        items.forEach(item => {
            const id = item.id;
            if (countMap[id]) {
                countMap[id].count += 1;
            } else {
                countMap[id] = {
                    ...item,
                    count: 1
                };
            }
        });
        return Object.values(countMap);
    }
    const handleReservations = () => {
        saveFormData();
        navigate('/reservations')
    }
    const getMapInformation = () => {
        dispatch(getBeachDashboardInformation(selectedDate))
    }

    useEffect(() => {
        const savedSearch = localStorage.getItem('searchInput');
        if (savedSearch) {
            setSearch(savedSearch);
        }
    }, []);

    useEffect(() => {
        const savedFormData = localStorage.getItem('reservationFormData');
        if (savedFormData) {
            setFormData(JSON.parse(savedFormData));
        }
    }, []);


    return (
        <div className="flex flex-col h-full">
            <div className="flex-grow flex flex-wrap overflow-hidden relative">
                <Space
                    onCreate={initViewPort}
                    pollForElementResizing={true}
                    ref={spaceRef}
                    style={{
                        width: "100%",
                        height: '100%',
                        overflowY: "scroll",
                        overflowX: "scroll",
                        border: '1px solid black',
                        position: 'relative',
                    }}>
                    {
                        map?.map((section, index) => (
                            section.type === 'ITEM' ?
                                (<DisabledAdminItem
                                    key={'item_disabled_dragged_' + index}
                                    index={index}
                                    item={section}
                                    addingNewReservation={viewMode === 'addNew'}
                                    clickedItemIndex={clickedItemIndex}
                                    setClickedItemIndex={setClickedItemIndex}
                                />)
                                : (<DisabledAdminSection key={'section_disabled_dragged_' + index} section={section}
                                                         index={index}/>)
                        ))
                    }
                </Space>
                <div
                    className={`md:absolute top-0 right-0 bg-white shadow-lg z-50 mt-3 rounded-xl ${selectedReservation ? 'flex flex-col h-[95vh] md:w-[20vw]' : ''}`}>
                    <div className="relative">
                        <button
                            onClick={handleToggleClick}
                            className="bg-white rounded-full absolute p-1 shadow-3xl -top-[20px] -left-[10px]"
                        >
                            {selectedReservation ? (
                                <CloseFullscreenIcon className="text-primary-orange" sx={{fontSize:'20px'}}/>
                            ) : (
                                <OpenInFullIcon className="text-primary-orange" sx={{fontSize:'20px'}}/>
                            )}
                        </button>
                        <p className="text-primary-dark-green font-bold xl:text-lg md:text-sm pt-4 px-2">Reservations</p>
                        {viewMode === 'reservationDetails' && (
                            <div
                                className="xl:flex items-center md:grid justify-between text-primary-dark-green gap-3 px-3 mb-2">

                                <div className="md:flex items-center gap-2">
                                    <button type="button" onClick={handleReservations}
                                            className="text-white bg-primary-dark-green px-2 py-2 rounded-lg">
                                        <DragIndicatorIcon/>
                                    </button>
                                    <button className="text-white bg-primary-dark-green rounded-md px-3 py-1 text-2xl"
                                            onClick={handleAddNewReservationClick}
                                    >+
                                    </button>
                                    <button type="button" onClick={getMapInformation}
                                            className="bg-primary-dark-green rounded-md py-2 px-2.5 text-sm"><LoopIcon
                                        className="text-white"/></button>
                                </div>
                            </div>
                        )}

                        {viewMode === 'addNew' && (
                            <div
                                className="xl:flex items-center justify-between text-primary-dark-green gap-3 mt-1 py-1 px-3 mb-2">
                                <p className="font-bold xl:text-lg md:text-sm">Add New Reservation</p>
                                <div className="md:flex items-center gap-2">
                                    <button className="text-white bg-primary-dark-green rounded-md px-3 py-1 text-2xl"
                                            onClick={() => handleFinishAdding()}>
                                        x
                                    </button>
                                    <button type="button" onClick={getMapInformation}
                                            className="bg-primary-dark-green rounded-md py-2 px-2.5 text-sm"><LoopIcon
                                        className="text-white"/></button>
                                </div>
                            </div>
                        )}

                        {selectedDate && (
                            <p className="px-3 mt-2 text-sm text-primary-orange mb-2">Selected Date: <span
                                className="font-bold">{selectedDate}</span></p>
                        )}
                        <p className="border-b border-primary-gray/10"></p>
                    </div>
                    {selectedReservation && (
                        <div className="flex-1 overflow-y-auto">
                            {renderView()}
                        </div>
                    )}
                    {(viewMode === 'addNew' && selectedReservation && selectedBeds.length > 0) &&
                        <div className="p-4">
                            <ul>
                                {selectedBeds.map(bed => (
                                    <li key={bed} className="list-disc list-disc-orange py-3">
                                        <div className="flex items-center justify-between text-sm font-archivo-light">
                                            <p className="text-primary-gray xl:text-[14px] md:text-xs"> {bed.count}x {bed.name}</p>
                                            <KingBedIcon className="text-primary-dark-green"/>
                                        </div>
                                        <div
                                            className="font-archivo-light flex items-center justify-between py-1 xl:text-[14px] md:text-xs">
                                            <p className="text-primary-gray">Amount</p>
                                            <p className="font-archivo-regular"> $ {bed.count * (checkIfWeekend(bed.selected_date) ? Number(bed.pivot.weekend_price) : Number(bed.pivot.weekday_price))}
                                            </p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ReservationsMap;
